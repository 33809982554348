<template>
    <div>
        <!--AxBasImagesDetail.vue-->
        <el-container>
            <el-main style="height: 600px;">
                <el-row>
                    <el-col :span="20">
                        <el-button-group>
                            <el-button type="" size="mini" @click="sysdetailadd">新增</el-button>
                            <el-button type="" size="mini" @click="sysdetailupdate">修改</el-button>
                            <el-button type="" size="mini" @click="sysdetailsave">保存</el-button>
                        </el-button-group>
                    </el-col>
                </el-row>
                <vxe-form title-align="right" title-width="140">
                    <vxe-form-gather span="24" :visible="false" >
                        <vxe-form-item title="guid" field="guid" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].guid" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].guid" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="编码" field="code" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].code" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].code" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="名称" field="name" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].name" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].name" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="链接" field="url" :item-render="{}" span="6">
                            <template #default>
                                 <div v-if="content.pagestatus !='view'">
                                    <el-upload  
                                        size="small"
                                        class="filter-item"
                                        name="excelFile"
                                        ref="upload"
                                        :on-change="handleChange"
                                        action="/kr/upload"
                                        :http-request="uploadUrl"
                                        :show-file-list="false"
                                        :file-list="fileList"
                                        accept="image/png,image/gif,image/jpg,image/jpeg">
                                        <img v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].url"
                                            placeholder="" size="mini" clearable :src="DataSet.tables[mastertable].rowsview[0].url" style="width:250px;height:250px;display:inline" />
                                    <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                                    </el-upload>
                                  </div>
                                  <!--:src=require('assets/'+imgUrl+'.jpg')-->
                                  <div id="t3" v-else>
                                        <img v-if="content.pagestatus ='view'" v-model="DataSet.tables[mastertable].rowsview[0].url"
                                            :src="DataSet.tables[mastertable].rowsview[0].url"
                                            placeholder="" size="mini"  />
                                  </div>
                                </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24" :visible="false" >
                        <vxe-form-item title="类型" field="vtype" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].vtype" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].vtype" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="备注" field="remark" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].remark" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].remark" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                </vxe-form>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_bas_imagesdetail",
        extends: ax.control1(),
        data() {
            return {
                vouchertype: "detail",
                mastertable: "ax_bas_images",
                model: "chanjet",
                settings: "axBasImages",
                datainit: {
                    "ax_bas_images": {
                        "keyname": "ax_bas_images",
                        "key": "guid",
                        "ptable": "",
                        "pkey": "",
                        "relation": "",
                        "datatype": "datafree",
                        "dataname": "主表",
                        "jsexps": [],
                        "hasseq": true,
                        "rows": [{
                               guid:"",
                               code:"",
                               name:"",
                               url:"",
                               vtype:"",
                               remark:"",
                        }]
                    }
                },
                fileList:[],
                imgUrl:"",
                selects:{
                "vtype":['1','1']
                }

            }
        },
        methods:{
            handleChange(file,fileList){
                this.fileList = fileList;

                console.log(file)
               // this.DataSet.tables['ax_z_bas_boma'].currentrow.name=file.name;
                
                //生成本地图片的url，直接src显示,删除或移动后url就失效了
                this.imgUrl = URL.createObjectURL(file.raw);
                console.log(this.imgUrl)
            },
            uploadUrl:function(param){
                this.isImported=true;
                var form = new FormData();
                form.append("file", param.file);
                //var id = this.DataSet.tables["ax_z_bas_boma"].currentrow.guid;
                //console.log(id);
                var _this=this;
                //form.append("id",id);
                ax.base.post('/chanjet/axBasImages/upload', form).then(data => {
                    console.log(data)
                    _this.DataSet.tables[_this.mastertable].rowsview[0].url = data.data.url;
                    _this.$XModal.message({ message: data.data, status: 'success' })
                    }).catch(data => {
                    _this.$XModal.message({ message: data.data, status: 'error',duration:10000 })
                });
            },
        }
    }
</script>

<style type="text/css">
    #t3 > img{
        max-width: 100%;
        /* width: 250px; */
        display: "inline";
        max-height: 100%; 
    }
</style>